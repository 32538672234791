import { Box, FormControl, FormLabel, Grid, type Theme } from "@mui/material";
import { update } from "ramda";
import React, { useContext, useMemo } from "react";
import { useDrop } from "react-dnd";

import makeStyles from "@mui/styles/makeStyles";

import { ELEMENT_TEMPLATES_TYPE, GridColumns } from "../helpers/const";

import { Sizing, Spacing } from "../types/enum";
import { type Element, ElementKind } from "../types/graphql";

import LessonContext from "../context/LessonContext";
import LessonEditPageSectionElement from "./LessonEditPageSectionElement";
import LessonEditPageSectionInstructions from "./LessonEditPageSectionInstructions";

/**
 * Types
 */
enum ElementLocation {
  Primary,
  Secondary,
  Tertiary,
}

interface Props {
  value: Element[];
  setValue: (elements?: Array<Element | undefined>) => void;
  columns?: GridColumns;
  index: number;
  sectionId: string;
  showGridDescription?: boolean;
}

/**
 * Constants
 */
const BLACK_LISTED_ELEMENTS = [ElementKind.Grid, ElementKind.Spacing, ElementKind.Divider];

const GRID_SIZE_FIRST_COL = new Map<GridColumns, Sizing>([
  [GridColumns.TwoEqualColumns, Sizing.Half],
  [GridColumns.TreeEqualColumns, Sizing.OneThird],
  [GridColumns.OneThirdTwoThirds, Sizing.OneThird],
  [GridColumns.TwoThirdsOneThird, Sizing.TwoThirds],
  [GridColumns.OneFourthThreeFourths, Sizing.OneFourth],
  [GridColumns.ThreeFourthsOneFourth, Sizing.ThreeFourths],
]);

const GRID_SIZE_SECOND_COL = new Map<GridColumns, Sizing>([
  [GridColumns.TwoEqualColumns, Sizing.Half],
  [GridColumns.TreeEqualColumns, Sizing.OneThird],
  [GridColumns.OneThirdTwoThirds, Sizing.TwoThirds],
  [GridColumns.TwoThirdsOneThird, Sizing.OneThird],
  [GridColumns.OneFourthThreeFourths, Sizing.ThreeFourths],
  [GridColumns.ThreeFourthsOneFourth, Sizing.OneFourth],
]);

const THREE_COLUMNS_GRID = 3;
/**
 * Helpers
 */
const columSize = (columnType: GridColumns | undefined, index: number) => {
  if (columnType === undefined) {
    return Sizing.Half;
  }
  if (index === 0) {
    return GRID_SIZE_FIRST_COL.get(columnType);
  }
  if (index === 2) {
    return Sizing.OneThird;
  }

  return GRID_SIZE_SECOND_COL.get(columnType);
};

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
    padding: theme.spacing(Spacing.ml, Spacing.xs, Spacing.ml, Spacing.m),
    borderRadius: theme.shape.borderRadius,
    border: `1px dashed ${theme.palette.action.disabled}`,
  },
}));

const LessonEditPageSectionGrid: React.FC<Props> = ({
  value,
  setValue,
  columns,
  index: gridIndex,
  sectionId,
  showGridDescription,
}: Props) => {
  const classes = useStyles();
  const { onEnhanceElementMetaForChild } = useContext(LessonContext);

  const [primary, secondary, tertiary] = value ?? [];

  const elements =
    columns === GridColumns.TreeEqualColumns || value.length === THREE_COLUMNS_GRID
      ? [primary, secondary, tertiary]
      : [primary, secondary];

  const onDropElement = (element: Element, location: ElementLocation) => {
    if (BLACK_LISTED_ELEMENTS.includes(element?.kind)) {
      return null;
    }
    const newElements = update(location, { ...element, meta: { createdAt: Date.now() } }, elements);
    setValue(newElements);
  };

  const onDeleteElement = (location: ElementLocation) => {
    const newElements = update(location, undefined, elements);
    setValue(newElements);
  };

  const onEditElement = (location: ElementLocation, value: any) => {
    const newElements = update(location, { ...elements[location], value }, elements);
    setValue(newElements);
  };

  const [, refPrimary] = useDrop({
    accept: ELEMENT_TEMPLATES_TYPE,
    drop: (element: Element) => onDropElement(element, ElementLocation.Primary),
  });

  const [, refSecondary] = useDrop({
    accept: ELEMENT_TEMPLATES_TYPE,
    drop: (element: Element) => onDropElement(element, ElementLocation.Secondary),
  });

  const [, refTertiary] = useDrop({
    accept: ELEMENT_TEMPLATES_TYPE,
    drop: (element: Element) => onDropElement(element, ElementLocation.Tertiary),
  });

  const refs = useMemo(() => {
    return [refPrimary, refSecondary, refTertiary];
  }, [refPrimary, refSecondary, refTertiary]);

  return (
    <FormControl fullWidth>
      {showGridDescription ? (
        <Box mb={Spacing.s}>
          <FormLabel>Grid</FormLabel>
        </Box>
      ) : null}

      <Grid spacing={Spacing.ml} container>
        {elements.map((element, index) => {
          return (
            <Grid xs={columSize(columns, index)} key={`${element?.meta.createdAt}${index}`} item>
              <div className={classes.root} ref={refs[index]}>
                {element ? (
                  <LessonEditPageSectionElement
                    index={index}
                    element={element}
                    onDeleteElement={() => {
                      onDeleteElement(index);
                    }}
                    onEditElement={(value) => {
                      onEditElement(index, value);
                    }}
                    setElementMeta={(key: string, value: string) => {
                      onEnhanceElementMetaForChild(sectionId, gridIndex, index, key, value);
                    }}
                    total={elements.length}
                    canMove={false}
                  />
                ) : (
                  <LessonEditPageSectionInstructions />
                )}
              </div>
            </Grid>
          );
        })}
      </Grid>
    </FormControl>
  );
};

export default LessonEditPageSectionGrid;
