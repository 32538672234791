import { Grid } from "@mui/material";
import React, { Fragment, useContext } from "react";
import { type Element, ElementKind, type Maybe } from "../types/graphql";

import LessonContext from "../context/LessonContext";
import LessonEditPageSectionElement from "./LessonEditPageSectionElement";

/**
 * Types
 */
interface Props {
  sectionId: string;
  elements?: Maybe<Element[]>;
}

const LessonEditPageSectionSandbox: React.FC<Props> = ({ elements, sectionId }: Props) => {
  const { onDeleteElement, onEditElement, onEnhanceElementMeta } = useContext(LessonContext);

  return (
    <Grid justifyContent="space-between" container>
      {elements?.map((element, index) => {
        const showAddRowButton =
          element.meta?.columnsLayout &&
          ((element.kind === ElementKind.Grid && elements[index + 1]?.kind !== ElementKind.Grid) ||
            (element.kind === ElementKind.Grid &&
              elements[index + 1]?.meta?.columnsLayout !== element?.meta?.columnsLayout));
        const showGridDescription =
          (element.kind === ElementKind.Grid && elements[index - 1]?.kind !== ElementKind.Grid) ||
          elements[index - 1]?.meta?.columnsLayout !== element?.meta?.columnsLayout;
        return (
          <Fragment key={element?.meta?.createdAt}>
            <LessonEditPageSectionElement
              sectionId={sectionId}
              index={index}
              element={element}
              onDeleteElement={() => {
                onDeleteElement(sectionId, index);
              }}
              onEditElement={(value: string) => {
                onEditElement(sectionId, index, value);
              }}
              setElementMeta={(key: string, value: string) => {
                onEnhanceElementMeta(sectionId, index, key, value);
              }}
              showGridDescription={showGridDescription}
              showAddRowButton={showAddRowButton}
              total={elements.length}
              canMove
            />
          </Fragment>
        );
      })}
    </Grid>
  );
};

export default LessonEditPageSectionSandbox;
